/* SKILLS */

#skills {
  padding-bottom: 30px;
  padding-top: 0;
  position: relative;
}

#skills .container .card {
  margin-bottom: 60px;
}

#skills h4 {
  border-bottom: 1px solid #ddd;
  margin-bottom: 30px;
  padding-bottom: 20px;
}

#skills .card .card-content {
  padding: 20px;
  border-radius: 0 0 2px 2px;
  background-clip: padding-box;
  box-sizing: border-box;
  padding-bottom: 30px;
}

#skills .container .row {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

#skills .col img.responsive-img {
  margin: 0 auto;
  max-height: 100px;
  padding-bottom: 10px;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
}
