@import 'components/about.css';
@import 'components/sidebar.css';
@import 'components/misc.css';
@import 'components/contact.css';
@import 'components/home.css';
@import 'components/projects.css';
@import 'components/experience.css';
@import 'components/education.css';
@import 'components/skills.css';
@import 'components/responsive.css';


/* GLOBAL */

html, body > #root > div {
  height: 100vh;
}

section {
  height: 100%;
}

html {
  font-family: "Roboto", sans-serif;
}

main {
  background: url(../../assets/images/bg.png) repeat;
  height: 100%;
  padding: 0px 0px !important;
}

h3 {
  line-height: 3.212rem;
  font-weight: 200;
  font-size: 2.92rem;
  padding: 30px 40px;
  text-transform: uppercase;
  color: #FFFFFF !important;
}


/* COMPONENTS */

.banner-sections {
  background-color: #008073 !important;
}
.card {
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}

.card, .card-product {
  border-radius: 6px;
}

.brown-text {
  color: #795548 !important;
}
