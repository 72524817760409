/* HOME */

#home {
  width: 100%;
  height: 100vh;
  background: url(../../images/home-bg.jpg) top center no-repeat;
  background-size: cover;
  position: relative;
}

#home h1 {
  margin: 0;
  font-size: 64px;
  font-weight: 700;
  line-height: 56px;
  color: #008073;
}


#home p {
  color: #008073;
  margin: 15px 0 0 0;
  font-size: 26px;
  font-family: "Poppins", sans-serif;
}
