/* MISCS */

#misc {
  /*height: 100vh;*/
}

#misc .card {
  max-width: 30rem;
  margin-bottom: 25px;
}

@media (max-width: 768px) {
  #misc .card {
    max-width: 100%;
  }
}

@media (max-width: 576px) {
  #misc .card {
    max-width: 100%;
  }
}
