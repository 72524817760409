/* EDUCATION */

#education .card-education {
  margin-bottom: 20px;
}

#education .card-education:hover {
  transform: scale(1.03);
  transition: ease 0.5s;
}

#education .card-education img {
  height: 100%;
  width: 150px;
  margin-left: 10px;
  object-fit: cover;
}

#education .card-body {
  display: inline-flex;
  align-items: center;
}

@media screen and (max-width: 472px) {
  #education .card-body {
    align-self: center;
  }
  #education .other, .details {
    display: none;
  }
}
