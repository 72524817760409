/* SIDEBAR */

#sidebar {
  overflow-x: hidden;
  background-color: #FFF;
  z-index: 999;
  will-change: left;
}

#sidebar a {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
}

#sidebar a:hover {
  color: #008073;
}

#sidebar ul li a {
  font-size: 1rem;
  padding: 10px 0 12px;
}

#sidebar ul li {
  transition: background-color 0.3s;
  list-style-type: none;
}

.navbar {
  line-height: 2rem;
}

.logo {
  border-bottom: 1px solid #ddd;
  box-sizing: content-box;
  padding: 30px 30px 40px;
  margin-bottom: 50px;
}

@media screen and (max-width: 767px) {
  #sidebar a {
    display: inline;
  }
  .sidebar-list {
    display: inline-flex;
    align-items: center;
    padding: 0px 5px 0px 5px;
    margin-bottom: 0px;
  }
  .sidebar-list li {
    text-align: center;
  }
  .navbar {
    padding-left: 50px;
  }
  .logo {
    border-bottom: 0px;
    margin-bottom: 0px;
  }
  .sidebar-list span {
    display:none;
  }
}

/*@media screen and (min-width: 768px) {
  .sidebar-list {
    padding-left: 0px;
  }
}*/

/*@media screen and (max-width: 1080px) {
  .sidebar-list {
    padding-left: 0px;
  }
}
*/
