/* ABOUT */

#about {
  height: 100vh;
  padding-bottom: 30px;
  padding-top: 0;
  position: relative;
}

#about h2 {
  border-left: 5px solid #795548;
  color: #795548;
  font-size: 125%;
  font-weight: 400;
  margin: 20px 0;
  padding-left: 1.5rem;
}

@media screen and (max-width: 1080px) {
  .rowAbout {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .card-group-about,
  .top-card-about {
    margin: 0 0 60px 0 !important;
  }
}
